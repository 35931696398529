
.c-navigation{
  text-align: center;
}

.c-navigation__menu ul li {
  display:block;
}

.c-navigation__footer {
  position: absolute;
  bottom: $padding;
  left: 0;
  right: 0;
}

.c-navigation__footer span {
  display: block;
  @include mq($br-small) {
      display:inline-block;
  }
  @include mq(450px) {
      display:inline;
  }
}
