// Typography
// Typographical base selectors. Define base styles on single element selectors here.

body {
  @include sans();
  color: $black;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

.tnum {
  -moz-font-feature-settings: "tnum" 1;
  -webkit-font-feature-settings: "tnum" 1;
	font-feature-settings: "tnum" 1;
}

a {
  color: $black;
  text-decoration: none;
  @include transition(color, $speed, $ease);
  &:hover {
    color: $textgray;
    text-decoration: none;
  }

}



p {
  margin-bottom: $baseline;
  &:last-child {
    margin-bottom: 0;
  }
}

.t-title {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: $baseline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
  font-size: inherit;
}

sup {
  top: -0.33em;
  font-size: 66.66%;
}

b, strong {
  font-weight: normal;
  display:inline-block;
}

.type--large,
.t-large {
  @include sans--large();
}

.type--small,
.t-small {
  @include sans--small();
}

.type--uppercase,
.t-uc {
  text-transform: uppercase;
}

.type--tag:after {
  content: ", ";
}
.type--tag:last-child:after {
  content: "";
}
