// Media
// Base selectors for images, video, audio...
/*
img, video {
    max-width: 100%;
    width: 100%;
    vertical-align: top;
}
*/

img.lazyload,
img.lazyloading {
	opacity: 0;
}

img.lazyloaded {
	opacity: 1;
	-webkit-transition: opacity $speed $ease, transform $speed $ease;
	-moz-transition: opacity $speed $ease, transform $speed $ease;
	-o-transition: opacity $speed $ease, transform $speed $ease;
	transition: opacity $speed $ease, transform $speed $ease;
}
