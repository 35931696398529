/**
 * Orders page
 *
*/

.orders {
	min-width: 100%;
	border-collapse: collapse;
	line-height: $baseline*1.125;

	& * + * { margin-top: 0; }

	& > tbody > tr {
		border-top: 1px solid $white;
	}

	& > tbody > tr > td {
		//border-top: 2px solid $white;
		padding: 0.75em 0.75em 0.75em;

		&:first-child { padding-left: 0; }
		&:last-child { padding-right: 0; }
	}

	th {
		text-align: left;
		padding: 0 0 $baseline*2 0;

		& > button {
			white-space: nowrap;
		}

		&:last-child {
			padding-right: 0;
		}
	}

/*
	th:nth-child(3),
	td:nth-child(3) {
		text-align: right;
	}
*/

	td:nth-child(3) {
		div {
			display:block;
			width:100%;
		}
		span.left{
			display:inline-block;
			text-align:left;
		}
		span.right{
			display:inline-block;
			text-align:right;
			float:right;
		}
	}

	.filter {
		font-weight: normal;
		//font-size: $text-s;

		label {
			white-space: nowrap;
		}

		button {
			margin-top: 0.75em;
		}
	}

	td {
		vertical-align: top;
	}

	td,
	th {
		border: none;
	}

	ul {
		padding: 0;
		margin: 0 0 0 1em;
	}

	table {
		width: 100%;
	}

}

.o-button-small,
.update-status input[type="submit"],
.update-status button {
	width:100%;
	background-color: $gray;
	margin-bottom: 2px;

	vertical-align: top;
	width: 100%;
	max-width: 20em;
	display: inline-block;
	text-align: left;
	padding: $baseline/2.5;
	background-color: $textdarkgray;
	color: $black !important;
	@include transition(background-color, $speed, $ease);
	&:hover,
	&.active {
		color: $black  !important;
		background-color: $white;
	}
}

/**
 * Gateway pages
 *
 */



.txn-summary {
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid $accent;
	padding-bottom: 1em;

	&>* + * {
		margin-top: 0;
	}
}

#sq-card {
	label,
	iframe {
	  display: block;
	}
	iframe {
		margin-top: 0;
	}
	.sq-input {
		outline: 1px auto $baseDark;;
		border: 3px solid white;
		box-sizing: content-box;
		width: calc(100% - 8px);
	}
	.sq-input--focus {
	  outline: 1px auto $accent;
	}
	.sq-input--error {
	  outline: 1px auto $warning;
	}

	#sq-expiration-date,
	#sq-cvv,
	#sq-postal-code {
		max-width: 5em;
	}

	& > div {
		padding: 1em;
	}

	& > div:first-child {
		background-color: $base;
		border-radius: 0.75em;
	}

	& > div {
		margin-top: 0;
	}

	.exp-cvv {
		display: flex;
		justify-content: space-between;

		div {
			margin-top: 0;
		}
	}
}

#sq-card {
	margin: 2em 0;

	.card {
		background-position: top -2px right 1em;
		background-size: auto 2em;
		background-repeat: no-repeat;
		border: 1px solid $base;
		max-width: 18em; /* Roughly twice the height, to approximate the ratio of a credit card */
	}
}

@media (min-width: 30em) {
	#sq-card {
		display: flex;
	}
}

#sq-card.visa .card {
	background-image: url('/assets/plugins/shopkit/img/visa.png');
	background-color: #E9F3F7;
}
#sq-card.masterCard .card {
	background-image: url('/assets/plugins/shopkit/img/mastercard.png');
	background-color: #FFF8EC;
}
#sq-card.JCB .card {
	background-image: url('/assets/plugins/shopkit/img/jcb.png');
	background-color: #F4FBF8;
}
#sq-card.americanExpress .card {
	background-image: url('/assets/plugins/shopkit/img/amex.png');
	background-color: #E6F5FC;
}
#sq-card.discover .card {
	background-image: url('/assets/plugins/shopkit/img/discover.png');
	background-color: #FEF4EB;
}
