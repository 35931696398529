.c-video {
  //background-color: #ff0000;
  position:relative;
  width: calc((100vh - #{$padding}*5.32) * 1.7777 );
  height: 100%;

  &.ratio--16-9 {
    width: calc((100vh - #{$padding}*5.32) * 1.7777 ) !important;
  }

  &.ratio--3-2 {
    width: calc((100vh - #{$padding}*5.32) * 1.5 ) !important;
  }

  &.ratio--1-1 {
    width: calc((100vh - #{$padding}*5.32)) !important;
  }

  &:hover,
  &:focus {
    .c-video__buttons{
      display:block;
    }
  }

  @include mq($br-med-small) {
    width: 100%;
    height: calc((100vw - #{$padding-smaller*2}) / 1.7777 );

    &.ratio--16-9 {
      width: 100% !important;
      height: calc((100vw - #{$padding-smaller*2}) / 1.7777 ) !important;
    }

    &.ratio--3-2 {
      width: 100% !important;
      height: calc((100vw - #{$padding-smaller*2}) / 1.5 ) !important;
    }

    &.ratio--1-1 {
      width: 100% !important;
      height: calc((100vw - #{$padding-smaller*2}) !important;
    }

  }

}

.c-video iframe {
  //width: auto;
  //max-width: none;
  //height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  .player {
    cursor: pointer !important;
  }
}

.c-video__buttons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display:none;
}

.c-video__button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display:none;
  span {
    position: absolute;
    left:50%;
    top:50%;
    @include transform(translate(-50%,-50%));
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.c-video__button.play {
  display:block;
}
.c-video__button.pause {
  display:none;
}

.c-video.is-playing {
  .c-video__button.play {
    display:none;
  }
  .c-video__button.pause {
    display:block;
  }
}


html.is-touch {
  .c-video .c-video__buttons{
    display:block;
  }
  .c-video.is-playing {
    .c-video__button.play {
      display:none;
    }
    .c-video__button.pause {
      display:block;
      opacity: 0;
    }
  }

}

@include mq($br-med-small) {
  .c-video .c-video__buttons{
    display:block;
  }
  .c-video.is-playing {
    .c-video__button.play {
      display:none;
    }
    .c-video__button.pause {
      display:block;
      opacity: 0;
    }
  }

}

.hidden {
  display:none;
}
