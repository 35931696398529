/**
 * Define common variables
 *
 */

$base: rgba(0,0,0,0.08);
$baseDark: rgba(0,0,0,0.15);
$baseDarker: rgba(0,0,0,0.3);
$baseDarkest: rgba(0,0,0,0.6);

$lighten: rgba(255,255,255,0.5);

$accent: #00a8e6;
$accentTranslucent: rgba($accent, 0.3);
$accentDark: darken($accent, 10);
$accentLight: desaturate(lighten($accent, 30), 40);
$accentPale: desaturate(lighten($accent, 45), 30);

$link: #0077dd;
$linkPale: desaturate(lighten($link, 30), 40);

$warning: #ffdc00;
$warningPale: desaturate(lighten($warning, 30), 10);

$success: #2ecc40;
$successPale: desaturate(lighten($success, 30), 10);

$danger: #CC1F1A;

$text-s: 0.8rem;

$breakpoint-xs-s: 30em;
$breakpoint-s-m: 45em;
$breakpoint-m-l: 75em;


/**
 * Helper classes
 *
 */

.hide {
	display: none !important;
}

/**
 * Layout
 *
 */

.wrapper {
	position: relative;
	background-color: hsla(0,0%,100%,0.9);
  overflow: hidden;
	padding: 0.5em 0.5em 5em;
}
.wrapper::before {
	content: '';
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	z-index: -1;
	background-position: center top;
	background-attachment: fixed;
	background-repeat: repeat-y;
	background-size: 100% auto; /* Fallback */
	background-size: cover;
}

@media (min-width: $breakpoint-s-m) {
	.wrapper {
		padding: 1em 1em 5em;
	}
}

.wrapper-main {
	width: 100%;
	padding: 1em;
	position: relative;
	main {
		border-bottom: 2px solid $accent;
		padding-bottom: 2em;
	}
}

@media (min-width: $breakpoint-s-m) {
	.wrapper {
		display: flex;
		align-items: flex-start;
	}
	.sidebar {
		order: 1;
		width: 15em;
		flex-shrink: 0;
		margin: 0 1em 0 0;
	}
	.wrapper-main {
		order: 2;
		flex-grow: 1;
	}
}

@media (min-width: $breakpoint-m-l) {
	.wrapper {
		justify-content: center;
		max-width: 80em;
		margin-left: auto;
		margin-right: auto;
	}
	.sidebar {
		width: 18em;
	}
}


/**
 * Typography
 *
 */

// Inline semantic tags

:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
	font-size: 1em;
	font-family: courier, monospace;
  color: inherit;
  padding: 0.2em 0.5em;
  background: $base;
  background: rgba(0,0,0,0.1);
}


// Links

.menu,
nav,
.list {
	a:hover,
	a:focus {
		text-decoration: none;
	}
}


// Lists

.menu,
.list {
	padding: 0;
	list-style: none;

	ul {
		margin-left: 1em;
	}
}


/**
 * Elements
 *
 */

video, iframe, img, figure, audio, object {
	max-width: 100%;
}

figure {
	margin: 1em 0;
}

svg {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: top;
	fill: $accent;
	transition: fill 0.2s;
}

table {
  border-collapse: collapse;

  td {
  	border: 1px solid $base;
  	padding: 0.5em;
  }
}

hr {
	height: 2px;
	width: 100%;
	background: $base;
	border: none;
}


/**
 * Buttons
 *
 */

button,
.button,
input[type="submit"] {
	display: inline-block;
	color: white;
	padding: 0.5em 0.5em;
	cursor: pointer;
	transition: background-color 0.2s, border-color 0.2s;
	background-color: $base;
	border: none;
	border-color: rgba(0,0,0,0.1);

	&:hover,
	&:focus {
		border-color: rgba(0,0,0,0.2);
		text-decoration: none;
	}

	&:active {
		color: black;
		background-color: $baseDark;
	}

	&.accent,
	&.warning,
	&.success {
		color: white;
		background-color: $accent;
		border-color: rgba(0,0,0,0.2);

		&:hover,
		&:focus {
			border-color: rgba(0,0,0,0.4);
		}

		&:active {
			background-color: $accentDark;
		}

		& svg {
			fill: white;
		}
	}

	&.warning {
		background-color: $warning;
		color: black;

		&:active {
			background-color: darken($warning, 10);
		}
	}

	&.success {
		background-color: $success;

		&:active {
			background-color: darken($success, 10);
		}
	}

	&.admin {
		font-size: $text-s;
		color: black;
		border-radius: 0;
		border: 0;
		padding: .5em;
		line-height: 1rem;

		svg {
			fill: black;
			width: 1em;
			height: 1em;

		}

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	&[disabled] {
		cursor: not-allowed;
		color: $baseDarker;
		background-color: $base;
		border-color: rgba(0,0,0,0.1);

		&:hover,
		&:focus,
		&:active {
			background-color: $base;
			border-color: rgba(0,0,0,0.1);
		}
	}

	del {
		display: block;
		font-size: 0.8em;
		margin-top: 0;
	}
}


// Toggle buttons

button[aria-expanded] {
	display: none; /* Overriden by JS */
	padding: 0;
	border: 0;
	background: inherit;
	line-height: 1;
}
button[aria-expanded] svg {
	width: 1em;
	height: 1em;
}
button[aria-expanded="true"] .expand,
button[aria-expanded="false"] .collapse, {
	display: none;
}

button[aria-expanded] + * {
	margin-top: 0;
	overflow-y: hidden;
	opacity: 1;

	& > li:first-child {
		margin-top: 1em;
	}
}
button[aria-expanded="false"] + * {
	opacity: 0;
	max-height: 0 !important; /* Overrides the inline max-height applied by JavaScript */
}
.animate button[aria-expanded] + * {
	transition: max-height 0.3s, opacity 0.5s;
}

.menu button[aria-expanded] {
	margin-left: 0.5em;
}


// Badges

.badge {
	color: $success;
	font-size: $text-s;
}



/**
 * Notification banners
 *
 */

.notification {
	//background-color: $accentPale;
	//padding: 1em;
	//border: 2px solid $base;
}
/*
.notification.warning {
	background-color: $warningPale;
}

.notification.success {
	background-color: $successPale;
}
*/


/**
 * Breadcrumb menus
 *
 */

.breadcrumb {
	margin-top: 0;
	font-size: $text-s;
	border-bottom: 2px solid $base;
	padding: 0 1em 1em;

	li {
		display: inline-block;

		&:after {
			content: '/';
			color: $baseDarker;
			display: inline-block;
			margin: 0 0.5em;
		}

		&:last-child:after {
			content: '';
		}
	}
}


/**
 * Subpage menus
 *
 */

.subpages {
	list-style: none;
	text-align: center;
	background-color: $base;
	padding: 0 0.5em 1em;

	li {
		display: inline-block;
		padding: 0 0.5em;
	}
}



/**
 * Logo
 *
 */

.logo {
	margin: 0;

	a, img {
		display: block;
	}

	img {
		margin: 0 auto;
	}
}

.sidebar .logo {
	display: none;
}

@media (min-width: $breakpoint-s-m) {
	.sidebar .logo {
		display: block;
	}

	.wrapper-main .logo {
		display: none;
	}
}



/**
 * Login form
 *
 */

.login {
	text-align: right;

	.notification {
		text-align: left;
	}

	& > button[aria-expanded] {
		font-size: $text-s;
	}

	form {
		display: flex;
		flex-direction: column;
		text-align: left;
		right: 1em;
		background-color: white;
		padding: 0 1em;
		z-index: 2;

		input {
			width: 100%;
		}
	}

	ul {
		padding-left: 0;
		text-align: center;
		width: 100%;

		li {
			margin-top: 0;
			display: inline-block;
			font-size: $text-s;
			margin: 0 0.5em;
		}
	}
}

.animate .login form {
	position: absolute;
	border: 2px solid $accent; /* For some reason border height is included in the document flow, causing a jump when the .animate is applied. So we're waiting until the form is removed from document flow to add the border. */
}

/**
 * Admin menu
 *
 */

.user {
	position: fixed;
	top:0;
	left:50%;
	transform: translateX(-50%);
	right:0;
	z-index: 9999;
	margin-bottom: 1em;
	border-bottom: 0;
	//background-color: #f0f0f0;
	display: flex;
	flex-wrap: wrap;
	width: auto;
	justify-content: center;

	a {
		margin: 0;
	}
}

/**
 * Primary navigation menu
 * Top-level pages (including cart)
 *
 */

.menu.primary {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	align-items: center;

	a {
		margin: 0 0.5em 0.5em;
		padding: 0.2em 0.5em;
	}

	a:not(.button):not(.flag) {
		border-bottom: 2px solid $base;
		border-top: 2px solid transparent; /* Align better with .cart button */
		color: inherit;

		&:hover,
		&:focus {
			color: $accent;
			border-bottom-color: $accentLight;
		}

		&:active {
			color: $accentLight;
		}

		&.active {
			border-bottom-color: $accent;
		}
	}

	.cart-wrap {
		margin: 0;
		padding: 0;
		white-space: nowrap;
	}

	.cart {
		line-height: 1;
	}

	.cart > * {
		margin-top: 0;
		display: inline;
		vertical-align: middle;
	}

	.flag {
		padding: 0;
		margin-left: 0;

		img {
			width: 1.5em;
			display: inline-block;
			vertical-align: middle;
		}
	}
}


/**
 * Sidebar
 *
 */

.sidebar section,
.sidebar h1 {
	padding: 1rem;
}

.sidebar section {
	margin-top: 0;

	& > h3 {
		margin-top: 0;
		border-bottom: 2px solid $base;
	}

	& > .menu.categories {
		margin-top: 0;
	}
}

.search {
	display: flex;
	align-items: space-between;

	input {
		width: 50%;
		flex-grow: 1;
		margin-right: 0.5em;
	}

	button {
		margin-top: 0;
	}
}

.menu.tags,
.menu.brands {
	li {
		margin: 0;
		display: inline;
		font-size: $text-s;
		line-height: 2;


		a {
			display: inline;
			background: $base;
			padding: 0.2em 0.5em;

			&:hover,
			&:focus {
				background-color: $accent;
				color: white;
			}

			&:active {
				background-color: $accentDark;
			}
		}
	}
}

.contact h4 { margin-bottom: 0; }
.contact h4 + p { margin-top: 0; }
.contact dl {
	dt {
		font-weight: bold;
	}
	dd,
	dd p:first-child {
		margin-left: 0;
		margin-top: 0;
	}

}


/**
 * Common list styles
 *
 */

.list + .button.admin {
	margin-top: 0;
}


/**
 * Featured product list
 *
 */

.list.products.featured {

	li {
		border: 2px solid $base;
	}

 	.product,
 	.product:hover,
 	.product:focus,
 	&:hover .product,
 	&:focus .product {
 		box-shadow: none;
 		background: none;
 	}

 	.product h3 {
 		margin-top: 0.25em;
 	}

 	.description {
 		margin: 0.5em;
 	}

 	.variant {
 		font-size: $text-s;
 	}

 	form {
 		margin-top: 0.25em;
 	}

 	button {
 		margin-top: 0.5em;
 	}

}


/**
 * Category list
 *
 */

.list.categories {
	display: flex;
	flex-wrap: wrap;
	margin-top: 2em;
	margin-left: -0.5em;
	margin-right: -0.5em;

	li {
		background-color: $accent;
		margin: 0.5em;
		flex-grow: 1;
	}

	li, a {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}

	a {
		font-size: 1.5em;
		background-size: auto 100%;
		display: flex;
		min-height: 200px;
		color: white;
		position: relative;

		span {
			align-self: flex-end;
			width: 100%;
			padding: 0 1rem 0.5rem 1rem;
			z-index: 2;
		}

		&:before {
			position: absolute;
			content: '';
			top: 0; right: 0; bottom: 0; left: 0;
			background-image: linear-gradient(10deg, rgba(black, 0.8) 0%, rgba(black, 0.2) 50%, transparent 100%);
			z-index: 1;
		}

		&:hover:before,
		&:focus:before {
			background-image: linear-gradient(10deg, rgba(black, 0.8) 0%, $accentTranslucent 100%);
		}
	}
}

@media (min-width: $breakpoint-s-m) {
	.list.categories li { width: 40%; }
}

@media (min-width: $breakpoint-m-l) {
	.list.categories li { width: 20%; }
}


/**
 * Product list
 *
 */

.brand {
	font-size: $text-s;
	text-transform: uppercase;
}

.list.products {
	margin-top: 1em;

	li {
		position: relative;

		&:hover .product,
		&:focus .product {
			background: $base;
			box-shadow: inset 0 -4px 0 -2px $accent, inset 0 0 0 2px $base;

			h3 {
				color: $link;
			}
		}
	}

	.product {
		display: block;
		color: black;
		box-shadow: inset 0 0 0 2px $base;

		img {
			display: block;
			width: 100%;
		}

		.description {
			margin-top: 0;
			padding: 1em;
		}

		h3 {
			margin-top: 0;
			margin-bottom: 0;
			font-size: 1.25em;
			line-height: 1.2;

			& ~ * {
				margin-top: 0.5em;
				margin-bottom: 0;
			}
		}

		.price {
			color: $accent;
			float: right;
			margin: 0 0 0.4em 1em;
			text-align: center;

			del {
				display: block;
				font-size: 0.8em;
				margin-top: 0;
			}
		}
	}

	.fullscreen {
		position: absolute;
		top: 0.5em;
		right: 0.5em;
		margin: 0;
		display: none;
		border-radius: 10em;
		padding: 0.5em;
		border: 2px solid white;
		background-color: white;

		&:hover,
		&:focus {
			background-color: $accent;

			svg {
				fill: white;
			}
		}

		&:active {
			background-color: $accentDark;
		}

		svg {
			display: block;
			fill: $accent;
		}
	}
	.product:hover + .fullscreen,
	.product:focus + .fullscreen,
	.fullscreen:hover,
	.fullscreen:focus {
		display: block;
	}
}

@media (min-width: $breakpoint-xs-s) {
	.list.products {
		display: flex;
		flex-wrap: wrap;
		margin-left: -0.5em;
		margin-right: -0.5em;

		li {
			margin: 0.5em;
			width: 12em;
			flex-grow: 1;
			display: flex;
		}

		.product {
			width: 100%;
		}

		img {
			flex-shrink: 0;
		}
	}
}

@media (min-width: $breakpoint-s-m) {
	.list.products li {
		max-width: 24em;
	}
}

.related {
	margin-top: 2em;
	border-top: 2px solid $base;
}

/**
 * Product page
 *
 */

main.product {
	.slider {
		margin-bottom: 1em;
	}
	.brand + h1 {
		margin-top: 0;
	}
}

.variants {
	margin: 0;
	text-align: center;
}

.variants form {
	border: 2px solid $base;
	padding: 0.5em;
	margin: 0 0.5em 1em;
	max-width: 20em;
	display: inline-block;
	vertical-align: top;
	text-align: left;

	h3 {
		margin: 0;
		line-height: 1.2;
	}

	.description p {
		margin: 0.5em 0 0;
	}

	.action {
		margin-top: 0.5em;

		select {
			margin: 0 0 0.5em;
			display: block;
			max-width: 100%;
		}

		button {
			display: inline-block;
			margin: 0;
		}

		.remaining {
			margin-top: 0.25em;
			margin-bottom: 0;
			font-size: $text-s;
			color: $accent;
		}
	}
}

@media (min-width: $breakpoint-s-m) {

	.product-details {
		display: flex;
	}

	.variants form {
		margin-right: 1em;
		margin-left: 0;
		width: 10em;
	}
	.variants form:first-child {
		margin-top: 0;
	}

	.slider + .variants {
		text-align: left;
		margin-left: 1em;

		form {
			margin-right: 0;
		}
	}
}


/**
 * Image slider
 *
 */

.slider {
	position: relative;

	.slide {
		background-size: 100%;
		background-position: center;
		opacity: 0;
		height: 0;
		overflow: hidden;
		transition: opacity 0.2s ease-in-out;
		margin-top: 0;

		img {
			display: block;
			max-height: 15em;
			margin: 0 auto;
		}
	}

	input {
		display: none;

		&:checked + .slide {
			height: auto;
			opacity: 1;
		}
	}

	.thumbnails {
		width: 100%;
		list-style: none;
		font-size: 0;
		text-align: center;
		padding-left: 0;

		li {
			font-size: 1rem;
			display: inline-block;
			margin: 0.5rem;
			width: 5em;
			max-width: 50px;
		}

		img {
			width: 100%;
			border-radius: 100%;
			display: block;
			border: 2px solid white;
			box-shadow: 0 0 0 2px $accent;
			cursor: pointer;
		}
	}
}

@media (min-width: $breakpoint-s-m) {
	.slider .slide img {
		max-height: 20em;
	}
}

@media (min-width: $breakpoint-m-l) {
	.slider .slide img {
		max-height: 30em;
	}
}


/**
 * Slideshow
 *
 */

.slideshow-nav {
	text-align: center;
	font-size: $text-s;

	.button {
		margin: 0 1em;

		&[disabled] svg {
			fill: $baseDark;
		}
	}

	svg {
		fill: black;
		display: block;
		margin: 0.2em auto 0;
	}
}

a.slideshow {
	display: block;
	color: black;
	box-shadow: inset 0 0 0 2px $base;

	&:hover,
	&:focus {
		background: $base;
		box-shadow: inset 0 -4px 0 -2px $accent, inset 0 0 0 2px $base;
		text-decoration: none;

		h3 {
			color: $link;
		}
	}

	.description {
		margin-top: 0;
		padding: 1em;

		h3,
		p {
			margin-top: 0;
			margin-bottom: 0;
		}

		.price {
			color: $accent;
			margin-top: 1em;
			margin-bottom: 1em;
		}
	}
}

@media (min-width: $breakpoint-m-l) {
	a.slideshow {
		display: flex;

		img { flex-grow: 1; }
		img + .description {
			max-width: 15em;
		}
	}
}
