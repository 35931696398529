// Font settings
$sans: 'HelveticaNeueLTStd-Roman', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif;
$normal: 400;

// Typography
@mixin sans() {
	font-family: $sans;
	font-size: 23px;
	line-height: $baseline;
	letter-spacing: -0.02em;

	@include mq($br-xlarge) {
		font-size: 21px;
	}
	@include mq($br-large) {
		font-size: 19px;
	}
	@include mq($br-med-large) {
		font-size: 17px;
	}
	@include mq($br-medium) {
		font-size: 16px;
	}
	@include mq($br-med-small) {
		font-size: 15px;
	}
	@include mq($br-small) {
		font-size: 14px;
	}

	@include mq($br-xsmall) {
		font-size: 12px;
	}

}

//Used for text as a slide
@mixin sans--slide() {
	font-family: $sans;
	font-size: 58px;
	line-height: $baseline;
	letter-spacing: -0.02em;

	@include mq($br-xlarge) {
		font-size: 52px;
	}
	@include mq($br-large) {
		font-size: 48px;
	}
	@include mq($br-med-large) {
		font-size: 42px;
	}
	@include mq($br-medium) {
		font-size: 36px;
	}
	@include mq($br-med-small) {
		font-size: 24px;
	}

}


//Used for splashpage
@mixin sans--xlarge() {
	font-family: $sans;
	font-size: 238px;
	font-size: 17vw;
	line-height: 1em;
	letter-spacing: -0.02em;

	@include mq($br-xlarge) {
		font-size: 238px;
		font-size: 17vw;
	}
	@include mq($br-large) {
		font-size: 238px;
		font-size: 17vw;
	}
	@include mq($br-med-large) {
		font-size: 200px;
		font-size: 17vw;
	}
	@include mq($br-medium) {
		font-size: 180px;
		font-size: 17vw;
	}
	@include mq($br-med-small) {
		font-size: 100px;
		font-size: 17vw;
	}
	@include mq($br-small) {
		font-size: 60px;
		font-size: 17vw;
	}

}

//Used for menu items
@mixin sans--large() {
	font-family: $sans;
	font-size: 96px;
	font-size: 6.66vw;
	line-height: 0.9em;
	letter-spacing: -0.02em;

	@include mq($br-med-small) {
		font-size: 48px;
	}
	@include mq($br-small) {
		font-size: 36px;
	}
	@include mq(460px) {
		font-size: 34px;
	}
	@include mq(400px) {
		font-size: 32px;
	}
	@include mq(330px) {
		font-size: 27px;
	}

}

@mixin sans--small() {
	font-family: $sans;
	font-size: 13px;
	line-height: 1.2em; //Slightly more line-height than baseline.
	letter-spacing: 0;

	@include mq($br-xlarge) {
		font-size: 12px;
	}
	@include mq($br-large) {
		font-size: 12px;
	}
	@include mq($br-med-large) {
		font-size: 12px;
	}
	@include mq($br-medium) {
		font-size: 11px;
	}
	@include mq($br-med-small) {
		font-size: 10px;
		line-height: 1.25em;
	}
	@include mq($br-small) {
		font-size: 9px;
		line-height: 1.33em;
	}

}
