// Forms
// Forms base selectors


/*
@media (min-width: 40em) {
	.inline label {
		display: inline-block;
		vertical-align: top;
		margin-top: 1em;
		margin-right: 1em;
	}
}

option + option {
	margin-top: 0;
}

fieldset {
	border: 0;
	padding: 0;
	margin-bottom: 3em;
}

[aria-invalid="true"]:not(:focus):not(:valid) {
	box-shadow: 0 0 0 2px $danger;
}
*/

// Honeypot field
.forRobots {
	visibility: hidden;
	z-index: -1;
	position: absolute;
}

input[type=checkbox],
input[type=radio] {
  border-radius: 50%;
  border:1px solid $white;
  width:0.75em;
  height:0.75em;
	margin-top: 0.125em;
  margin-right: $baseline/3;
  float:left;
}

input[type=checkbox]:checked,
input[type=radio]:checked {
  background-color: $white;
}

fieldset {
	border: none;
	padding: 0;
	margin: 0;
}

legend,
.legend-style {
	@include parent-behaviour();
	 margin-top: $baseline*1.5;
	 margin-bottom: $baseline/1.5;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

/*
form {
  @include parent-behaviour;
}
*/

input,
textarea,
button,
select,
.input-style {

  //@include user-select(text);
  font-family: inherit;
  color: inherit;
  text-transform: inherit;
  font-weight: inherit;
  line-height: inherit;

  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  border:0;
  border-radius: 0;
  background-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;

  vertical-align: middle;
  height: auto;
  display:inline-block;

}



input,
button,
.button,
textarea,
.input-style {
	//font-size: 1em;
	//line-height: 1.6;
	max-width: 100%;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea,
.input-style{
	background-color: transparent;
	border-bottom: 2px solid $white;
	padding: 0.2em 0em; /* Fallback */
	padding: calc(0.2em + 1px) 0;
	width: 100%;
	max-width: 75em;

	&.short {
		width: 10em;
	}

	&:focus {
		border-color: $white;
	}
}

textarea {
	min-height: 10em;
}

input[disabled] {
	background-color: $textdarkgray;
}

.input-style.readonly {
	cursor: initial;
	//color: $textdarkgray !important;
}

input[readonly] {
	border: none;
	padding-left: 0;
	padding-right: 0;
}

form button,
form input[type="submit"] {
	display: block;
}

label {
	display: block;
}

label > span {
	display: inline-block;

	&.help {
		margin-top: 0;
	}
}

/*
button,
select,
option {
	text-transform: uppercase;
}
*/

::-webkit-input-placeholder { color: $textdarkgray; }
::-moz-placeholder      	  { color: $textdarkgray; }
:-ms-input-placeholder    	{ color: $textdarkgray; }
input:-moz-placeholder    	{ color: $textdarkgray; }
