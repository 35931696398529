.c-header__banner {
	line-height: 1em;
	z-index: $z-header;
	position: fixed;
	top:0;
	left:0;
	right:0;
	display:block;
	height: auto;
  @include padding();
	opacity: 1;
	@include transition(opacity, $speed, $ease);

	&.is-hidden{
	 opacity: 0;
	}
}

.c-header__banner__title {
	opacity: 0;
	@include transition(opacity, $speed, $ease);
}

body.overlay-nav-is-open {
	.c-header__banner__title {
		opacity: 1;
	}
}

.c-header__banner div {
	position: relative;
	display:inline-block;
  @include padding();

	&:first-child {
		float:left;
	}

	&.c-header__banner__title {
		width: auto;
		white-space: nowrap;
		left:50%;
		position: absolute;
		@include transform(translateX(-50%));
	}

	&:last-child {
		float:right;
	}
}


.c-header__btn-sound-container {
	position: absolute;
	right: 0;
  @include padding();
}

.c-header__btn-sound {
	cursor: pointer;
  display: inline-block;
  float: right;
  padding: $padding/4 $padding/2.75;
  @include transition(opacity, $speed, $ease);
	pointer-events: none;
	opacity: 0 !important;

	&.is-visible {
		pointer-events: auto;
		opacity: 1 !important;
	}
}

.c-header__btn-container{
	float:right;
	// position: absolute;
	// right: 0;
  // @include padding();
}

.c-header__btn {
	cursor: pointer;
  display: inline-block;
	width:1em;
	height:1em;
	background-repeat:no-repeat;
	background-position:center center;
	background-size: 85%;
}

body.is-touch {
	.c-header__btn--fullscreen {
	  display:none;
	}
}

.c-header__btn--fullscreen {
  background-image: url('../img/icon-fullscreen.svg');
  @include mq($br-medium) {
		display:none;
  }
}
