.product__sidebar {
  background-color: $black;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 33.33vw;
  min-width: 400px;
  z-index: $z-sticky;

  @include mq($br-medium) {
    width: 100%;
    height: auto;
    position: relative;
    bottom: initial;
    //padding-bottom: 0;
  }

}

.product__row {
  //margin-top: $baseline;
  padding-top: $baseline/2;
  padding-bottom: $baseline/3;
	border-bottom: 2px solid $white;
}

.product__title__title {
  max-width: 75%;
  display: inline-block;
}

a.product__variant,
a.product__variant--current {
  position:relative;
  padding:0.33em;
  margin:0;
  // span {
  //   color: $textdarkgray !important;
  // }
  // &:hover {
  //   color: $white !important;
  //   span {
  //     color: $white !important;
  //   }
  // }
}

a.product__variant span  {
  color: $textdarkgray !important;
}

a.product__variant--current span  {
  color: $white !important;
}

//
// .product__variant--current:after {
//   content:"";
//   position:absolute;
//   display:inline-block;
//   left:0;
//   top:0;
//   border: 2px solid $white;
//   border-radius: 50%;
//   width:1.5em;
//   height:1.5em;
//   font-size:inherit;
//   z-index:-1;
// }
