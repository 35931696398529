/*! Flickity v2.1.1
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  //margin-left: $padding;
  //margin-right: $padding;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

//Slider
//Slider component
/*
.c-slider {
  width: 100vw;
  height: auto;
  height: calc(100vh - #{$padding*5});
  position: relative;

  opacity: 0;
  @include transition(opacity, $normal, $ease);

}*/


// enable Flickity by default
.c-slider:after {
  content: 'flickity';
  display: none; // hide :after

  @include mq($br-med-small) {
    content: '';
  }
}

.c-slider.is-hidden {
  opacity: 0;
}

.c-slider.flickity-enabled {
  opacity: 1;
}

.c-slider {
  min-height: 300px;
  height: 100%;

  @include mq($br-med-small) {
    padding-top: $padding-smaller*2.66;
  }
}

.c-slider--product {
  padding-left: calc((#{$padding}*1.33) / 3 );
  left: 33.33vw;
  @include mq($br-medium) {
    padding-top: 0!important;
    padding-left: 0;
    left:0;
    .c-slider__cell {
      padding-top: 0;
    }
  }
  @include mq($br-med-small) {
    padding-top: 0!important;
    .c-slider__cell {
      padding-top: 0;
    }
  }

}

.c-slider--product .c-slider__cell__image {
  width: auto;
  max-width: calc(66.66vw - (#{$padding}*1.33)*3);
  height: auto;
  max-height: 100%;

  @include mq($br-medium) {
    width: auto;
    max-width: none;
    height: 100%;
    max-height: auto;
  }
  @include mq($br-med-small) {
    display:none;
	}

}

.c-slider--product .c-slider__cell {
  @include mq($br-medium) {
    height: 50vh;
  }
  @include mq($br-med-small) {
    height: auto;
	}
}

//Slider cells
.c-slider__cell {
  display: block;
  width: auto;
  height: 100vh;
  @include transform(scale(1));
  padding-top: $padding*2.66;
  padding-bottom: $padding*2.66;
  padding-left: $padding*1.33;
  padding-right: $padding*1.33;
  @include mq($br-med-large) {
    padding-top: $padding-small*2.66;
    padding-bottom: $padding-small*2.66;
    padding-left: $padding-small*1.33;
    padding-right: $padding-small*1.33;
	}
	@include mq($br-med-small) {
    text-align: center;
    height: auto;
    padding-left: $padding-smaller;
    padding-right: $padding-smaller;
    //padding-top: $padding-smaller/2;
    padding-top: 0;
    padding-bottom: $padding-smaller;
	}
  -webkit-transition: opacity $speed $ease, transform $speed $ease;
	-moz-transition: opacity $speed $ease, transform $speed $ease;
	-o-transition: opacity $speed $ease, transform $speed $ease;
	transition: opacity $speed $ease, transform $speed $ease;
}

.c-slider__cell__image {
  width: auto;
  max-width: none;
  height: 100%;

  opacity: 0;
  -webkit-transition: opacity $speed $ease, transform $speed $ease;
	-moz-transition: opacity $speed $ease, transform $speed $ease;
	-o-transition: opacity $speed $ease, transform $speed $ease;
	transition: opacity $speed $ease, transform $speed $ease;

  @include mq($br-med-small) {
    display:none;
	}

}

.c-slider__cell__image-mobile {
  display:none;
  @include mq($br-med-small) {
    display:block;
    width: 100%;
    height: auto;
	}
}

.c-slider__cell__text {
  @include sans--slide();
  display:block;
  position: relative;
  width: calc((100vh - #{$padding}*2) * 1.5 );
  max-width: calc(100vw - #{$padding}*4);
  height: 100%;
  text-align: center;
  padding: $padding;
  @include mq($br-med-large) {
    width: calc((100vh - #{$padding-small}*2) * 1.5 );
    max-width: calc(100vw - #{$padding-small}*4);
    padding: $padding-small;
	}
	@include mq($br-med-small) {
    display:table;
    width: 100%;
    max-width: 100%;
    padding-left: $padding-smaller*1.33;
    padding-right: $padding-smaller*1.33;
    min-height: calc((100vw - #{$padding-smaller}*2.66) / 1.5 );
	}
}

/*
.c-slider__cell {
  opacity: 0.5;
}

.c-slider__cell.is-selected {
  opacity: 1;
}
*/

/* fade in lazy loaded image */
.c-slider__cell__image.lazyloaded,
.c-slider__cell__image.flickity-lazyloaded,
.c-slider__cell__image.flickity-lazyerror,
.c-slider__cell__video.lazyloaded,
.c-slider__cell__video.flickity-lazyloaded,
.c-slider__cell__video.flickity-lazyerror {
  opacity: 1;
}

//Captions
.c-slider__caption-container {
	position:fixed;
	bottom:0;
	left: 50%;
	right: auto;
	display:block;
	width: auto;
	height: auto;
  @include padding();
	text-align: center;
	line-height: 1em;
	@include transform(translateX(-50%));
	@include transition(opacity, $speed, $ease);
	.c-slider__caption {
    line-height: 1em;
		display: inline-block;
    @include padding();
		width: auto;
		white-space: nowrap;
	}

  @include mq($br-med-small) {
    display:none;
  }
}

.c-caption-container {
  display:none;
  @include padding();
  text-align: center;
  line-height: 1em;
  .c-caption {
    line-height: 1em;
    display: inline-block;
    @include padding();
    width: auto;
    white-space: nowrap;
  }
  @include mq($br-med-small) {
    display:block;
  }
}

.c-slider__btn:disabled {
  opacity: 0.3;
  cursor: auto;
}


//Buttons and slider hoverstates
.c-slider:hover {
  .c-slider__btn {
    opacity: 1;
  }
}

.c-slider__area-prev,
.c-slider__area-next {
  cursor:pointer;
  position: absolute;
  top: 0;
  width: 33.33vw;
  height:100%;
  @include mq($br-med-small) {
    display:none;
  }
  &:hover {
    .c-slider__btn {
      opacity:1;
    }
  }
}


.c-slider__area-prev {
  cursor: w-resize;
  left: 0;
}

.c-slider__area-next {
  cursor: e-resize;
  right: 0;
}

.c-slider__btn {
  position: absolute;
	cursor: pointer;
  display: inline-block;
	width:1em;
	height:1em;
	background-repeat:no-repeat;
	background-position: center center;
	background-size: 85%;
  opacity:0;
	@include transition(opacity, $speed, $ease);
  @include transform(translateY(2px));
  bottom: $padding;
  @include mq($br-med-large) {
    bottom: $padding-small;
	}
	@include mq($br-med-small) {
    bottom: $padding-smaller;
	}
}

.c-slider__btn--prev {
  left: $padding;
  @include mq($br-med-large) {
    left: $padding-small;
	}
	@include mq($br-med-small) {
    left: $padding-smaller;
	}
  background-image: url('../img/icon-prev.svg');
}

.c-slider__btn--next {
  right: $padding;
  @include mq($br-med-large) {
    right: $padding-small;
	}
	@include mq($br-med-small) {
    right: $padding-smaller;
	}
  background-image: url('../img/icon-next.svg');
}

.u-inverted {
  .c-slider__btn--prev {
    background-image: url('../img/icon-prev--white.svg');
  }

  .c-slider__btn--next {
    background-image: url('../img/icon-next--white.svg');
  }
}

// .c-slider.hover-prev {
// 	cursor: url('../img/icon-prev.svg')  5 9, auto;
// 	cursor: -webkit-image-set(
// 	url('../img/icon-prev.svg') 1x,
// 	url('../img/icon-prev.svg') 2x
// 	) 5 9, auto;
// }
//
// .c-slider.hover-next {
// 	cursor: url('../img/icon-next.svg')  5 9, auto;
// 	cursor: -webkit-image-set(
// 	url('../img/icon-next.svg') 1x,
// 	url('../img/icon-next.svg') 2x
// 	) 5 9, auto;
// }
