/**
 * @license
 * MyFonts Webfont Build ID 3628495, 2018-08-27T03:59:53-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HelveticaNeueLTStd-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-55-roman-189171/
 * Copyright: Copyright &#x00A9; 2014 Monotype Imaging Inc. All rights reserved.
 * Licensed pageviews: 250,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3628495
 *
 * © 2018 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/375dcf");
@font-face {font-family: 'HelveticaNeueLTStd-Roman';src: url('../fonts/375DCF_0_0.eot');src: url('../fonts/375DCF_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/375DCF_0_0.woff2') format('woff2'),url('../fonts/375DCF_0_0.woff') format('woff'),url('../fonts/375DCF_0_0.ttf') format('truetype');}
