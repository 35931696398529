// Layout

// Layout variables

$max_container_width: 100%;
$max_text_width: 39em;

$baseline: 1.25em;
$padding: 1.25em;
$padding-small: 1.2em;
$padding-smaller: 1em;

// Breakpoints
$br-xlarge:           1800px;
$br-large:            1440px;
$br-med-large:        1280px;
$br-medium:           1024px;
$br-med-small:        768px;
$br-small:            515px;
$br-xsmall:           340px;

// Grid

@mixin container() {
  @include parent-behaviour;
  width: 100%;
  max-width: $max_container_width;
  margin-left: auto;
  margin-right: auto;
  padding-top: $padding/2;
  padding-bottom: $padding/2;
  @include mq($br-med-large) {
    padding-top: $padding-small/2;
    padding-bottom: $padding-small/2;
	}
	@include mq($br-med-small) {
    padding-top: $padding-smaller/2;
    padding-bottom: $padding-smaller/2;
	}
}

@mixin container--less-padding() {
  @include parent-behaviour;
  width: 100%;
  max-width: $max_container_width;
  margin-left: auto;
  margin-right: auto;
  padding-top: $padding/4;
  padding-bottom: $padding/4;
  @include mq($br-med-large) {
    padding-top: $padding-small/4;
    padding-bottom: $padding-small/4;
	}
	@include mq($br-med-small) {
    padding-top: $padding-smaller/4;
    padding-bottom: $padding-smaller/4;
	}
}

@mixin container-item() {
  padding-left: $padding/2;
  padding-right: $padding/2;
  @include mq($br-med-large) {
    padding-left: $padding-small/2;
    padding-right: $padding-small/2;
	}
	@include mq($br-med-small) {
    padding-left: $padding-smaller/2;
    padding-right: $padding-smaller/2;
	}
}

@mixin section() {
  position: relative;
  @include parent-behaviour;
}

@mixin section--padding() {
  position: relative;
  @include parent-behaviour;
  padding-top: $padding/2;
  padding-bottom: $padding/2;
  @include mq($br-med-large) {
    padding-top: $padding-small/2;
    padding-bottom: $padding-small/2;
	}
	@include mq($br-med-small) {
    padding-top: $padding-smaller/2;
    padding-bottom: $padding-smaller/2;
	}
}

@mixin section--padding--less() {
  position: relative;
  @include parent-behaviour;
  padding-top: $padding/4;
  padding-bottom: $padding/4;
  @include mq($br-med-large) {
    padding-top: $padding-small/4;
    padding-bottom: $padding-small/4;
	}
	@include mq($br-med-small) {
    padding-top: $padding-smaller/4;
    padding-bottom: $padding-smaller/4;
	}
}

@mixin section--padding--more() {
  position: relative;
  @include parent-behaviour;
  padding-top: $padding*2;
  padding-bottom: $padding*2;
  @include mq($br-med-large) {
    padding-top: $padding-small*2;
    padding-bottom: $padding-small*2;
	}
	@include mq($br-med-small) {
    padding-top: $padding-smaller*2;
    padding-bottom: $padding-smaller*2;
	}
}

@mixin col--padding-bottom() {
  @include parent-behaviour;
  padding-bottom: $padding*2;
  @include mq($br-med-large) {
    padding-bottom: $padding-small*2;
  }
  @include mq($br-med-small) {
    padding-bottom: $padding-smaller*2;
  }
}

@mixin row($inside: false) {
  @if($inside == true) {
    margin-left: -$padding/2;
    margin-right: -$padding/2;
    @include mq($br-med-large) {
      margin-left: -$padding-small/2;
      margin-right: -$padding-small/2;
  	}
  	@include mq($br-med-small) {
      margin-left: -$padding-smaller/2;
      margin-right: -$padding-smaller/2;
  	}
  }
  @else {
    @include container();
    padding-left: $padding/2;
    padding-right: $padding/2;
    @include mq($br-med-large) {
      padding-left: $padding-small/2;
      padding-right: $padding-small/2;
  	}
  	@include mq($br-med-small) {
      padding-left: $padding-smaller/2;
      padding-right: $padding-smaller/2;
  	}
  }
  &:before, &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin row-no-padding() {
  @include container();
  padding-left: 0;
  padding-right: 0;

  &:before, &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin col($width: 1, $paddingBool: true, $centered: false) {
  @if($centered == true) {
    margin-left: auto;
    margin-right: auto;
    display:block;
  }
  @else {
    float: left;
  }
  width: percentage($width);
  @if($paddingBool == true) {
    padding-left: $padding/2;
    padding-right: $padding/2;
    @include mq($br-med-large) {
      padding-left: $padding-small/2;
      padding-right: $padding-small/2;
  	}
  	@include mq($br-med-small) {
      padding-left: $padding-smaller/2;
      padding-right: $padding-smaller/2;
  	}
  }
}

@mixin push($width: 0) {
  //position: relative;
  margin-left: percentage($width);
}
@mixin pull($width: 0) {
  //position: relative;
  margin-right: percentage($width);
}

/*
Paddings
*/

@mixin padding() {
  padding: $padding/2;
  @include mq($br-med-large) {
    padding: $padding-small/2;
	}
	@include mq($br-med-small) {
    padding: $padding-smaller/2;
	}
}

@mixin padding--left-right() {
  padding-left: $padding/2;
  padding-right: $padding/2;
  @include mq($br-med-large) {
    padding-left: $padding-small/2;
    padding-right: $padding-small/2;
	}
	@include mq($br-med-small) {
    padding-left: $padding-smaller/2;
    padding-right: $padding-smaller/2;
	}
}

@mixin padding--left() {
  padding-left: $padding/2;
  @include mq($br-med-large) {
    padding-left: $padding-small/2;
	}
	@include mq($br-med-small) {
    padding-left: $padding-smaller/2;
	}
}

@mixin padding--right() {
  padding-right: $padding/2;
  @include mq($br-med-large) {
    padding-right: $padding-small/2;
	}
	@include mq($br-med-small) {
    padding-right: $padding-smaller/2;
	}
}

@mixin padding--top-bottom() {
  padding-top: $padding/2;
  padding-bottom: $padding/2;
  @include mq($br-med-large) {
    padding-top: $padding-small/2;
    padding-bottom: $padding-small/2;
	}
	@include mq($br-med-small) {
    padding-top: $padding-smaller/2;
    padding-bottom: $padding-smaller/2;
	}
}


@mixin padding--bottom() {
  padding-bottom: $padding/2;
  @include mq($br-med-large) {
    padding-bottom: $padding-small/2;
	}
	@include mq($br-med-small) {
    padding-bottom: $padding-smaller/2;
	}
}

@mixin padding--top() {
  padding-top: $padding/2;
  @include mq($br-med-large) {
    padding-top: $padding-small/2;
	}
	@include mq($br-med-small) {
    padding-top: $padding-smaller/2;
	}
}
