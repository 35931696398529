//Container
//Acts like a wrapper/inner div

.o-container {
	@include container();
}

.o-container--less-padding {
	@include container--less-padding();
}

.o-container--page {
	@include container();
}

.o-container-item {
	@include container-item();
}


.o-container-full {
	@include child-overlay-behaviour();
}
