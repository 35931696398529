.c-splash {
	@include overlay(100%, 100%, $z-below, $white);
	opacity: 0;
	@include transition(opacity, $speed, $ease);

	&.is-active {
		z-index: $z-above-all;
		pointer-events: auto;
		opacity: 1;
		@include transition(opacity, $speed, $ease);
	}
}

.c-splash-text-container {
  @include transition(opacity, $speed, $ease);
	@include parent-behaviour();
  opacity: 0;
}

.c-splash.has-text-visible {
  .c-splash-text-container {
    opacity: 1;
  }
}

.o-banner {
	position:relative;
  display:table;
  text-transform: uppercase;
  @include sans--xlarge();
	width: 100%;
	height: 100vh;
	overflow: hidden;
	white-space: nowrap;
	cursor:pointer;
}

.o-banner__title {
	user-select: none;
	display:table-cell;
	vertical-align: middle;
	width:auto;
	height:100%;
	padding: 0;
	//top:0;
	//left: 50%;
	@include transform(translate(-50%,0));
	//@include transition(transform, 0.440s, linear);
	white-space: nowrap;
}

.o-banner__animate{
	display:block;
}
