//General

html {
	height: 100%;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
}

html, body {
	width: 100%;
	min-height: 100%;
	overflow-x: hidden;
	background-position: center top;
	background-attachment: fixed;
	background-repeat: repeat-y;
	background-size: 100% auto; /* Fallback */
	background-size: cover;
}

*, *:before, *:after {
	// https://www.paulirish.com/2012/box-sizing-border-box-ftw/
  box-sizing: inherit;
}

body {
	@include transition(background-color, $speed, $ease);
}

body.no-scroll {
	overflow: hidden;
}

//Focus view is activated first when clicking on an image within a gallery.
body.focus-view {
	.c-header__banner,
	.c-slider__cell:not(.is-selected),
	.c-slider__caption-container {
		opacity: 0;
	}
}

//Zoom view is activated shortly after the focus view.
body.zoom-view {
  //background-color: $black;
  overflow:hidden;
}

body.zoom-view .c-slider__cell.is-selected .c-slider__cell__image,
body.zoom-view .c-slider__cell.is-selected .c-slider__cell__video {
  @include transform(scale(1.25));
}

//Disable pointer-events for every element except the body class itself.
body.disable-clicks > * {
	pointer-events: none;
}

/*
This view is toggled after user inactivity.
Similar to focus-view. Needs different class name to avoid overriding in JS.
Inactivity view should only be triggered on the Homepage(Gallery) and Shop page
*/
body.inactivity-view {
	.c-header__banner,
	.c-slider__cell:not(.is-selected),
	.c-slider__caption-container {
		opacity: 0;
	}
}

.barba-container {
	position: relative;
}

body.page-transition-helper {
	.page-wrapper {
		@include transform(translate3d(0,0,0));
		@include transition(transform, $normal, $ease);
	}
}

body.page-is-pushed {
	.page-wrapper {
		@include transform(translate3d(0,27.5vw,0));
	}
}
