/**
 * Cart page
*/

form.shipping {

    .shipping-rates {
      float:left;
    }

    label {
      text-align: left;
      //float:left;
      display:block;
    }

	  button {
      text-align: right;
      float:right;
    }
}

label > span {
  text-align: left;
}

.c-cart__table {
  min-width:100%;

}

.table-overflow {
	overflow-x: auto;
}

.c-checkout {
	min-width: 100%;
	border-collapse: collapse;
	text-align: left;

  * { font-weight: normal; }
	& * + * { margin-top: 0; }

	td {
		display: inline-block;

		img {
			float: left;
			margin: 0 $baseline 0 0;
      max-height: 150px;
      @include mq($br-med-small) {
        max-height: 100px;
      }
		}

		&:first-child {
			padding-left: 0;
			&:after {
				content: '';
				display: block;
				clear: both;
			}
		}

		select {
      text-align: left;
      float:left;
			max-width: 100%;
		}
	}

	td,
	th {
		border: none;
	}

	thead tr {
		display: none;
	}

	tbody tr {
		width: 100%;
		display: block;

    //padding-top: $baseline;
    //padding-bottom: $baseline;
  	border-bottom: 2px solid $white;

		td:first-child {
			width: 100%;
			display: block;
			//text-align: left;
			padding-top: $baseline/1.5;
		}

    td:last-child {
			text-align: right;
		}


		td:not(:first-child) {
			padding-bottom: $baseline/1.5;
		}

		& td:first-child {
			border-top: 2px solid $white;
		}

	}

	tfoot {

    padding-top: $baseline/1.5;
    padding-bottom: $baseline/3;
  	border-top: 2px solid $white;
		//border-top: 2px solid $base;

		tr {
			display: block;
			font-size: 0;

      td {
        vertical-align: top;
        @include sans();
				//font-size: 1rem;
				width: 50%;

				&:first-child {
					text-align: left;
				}

        &:last-child {
					text-align: right;
				}

			}

			&:last-child {
        border-top: 2px solid $white;
				font-weight: normal;
			}

		}

		.discount {
			text-align: left;
			font-size: $text-s;
			white-space: nowrap;

			input,
			button {
				display: inline-block;
			}

			input {
				max-width: 40%;
			}
		}
	}

  .c-cart__item {
    width: 50%;
  }

  .c-cart__price form {
  	@include transition(opacity, $speed, $ease);
    display:inline-block;
		margin-right: $baseline;
  }

	.c-cart__quantity {
		white-space: nowrap;
		margin-right: $baseline/1.5;
    span {
      min-width: 1em;
    }
	}
	.c-cart__quantity > form,
	.c-cart__quantity > span {
		display: inline-block;
		vertical-align: middle;
	}
	.c-cart__quantity button {
		background: inherit;
		border: none;
		padding: 0 $baseline/5;
		margin: 0;
    line-height: 0;

		&[disabled] {
			opacity: 0.4;
		}
	}

  select[name="country"] {
    text-transform: none;
  }
	select[name="country"] + button,
	select[name="shipping"] + button {
		//font-size: $text-s;
		display: inline-block;
		vertical-align: middle;
		//margin: 0.25em 0 1em $baseline/1.5;
	}

	.shipping {
    //visibility: hidden;
    //position:absolute;
    //pointer-events: none;
		fieldset {
			margin: 0;

			& + fieldset {
				margin-top: 1em;
			}
		}
		legend {
			width: 100%;
			text-align: left;
		}
	}
}

.c-checkout tr {
  &:hover {
    .c-cart__price form {
      opacity: 1;
    }
  }
  .c-cart__price form{
    opacity: 0;
  }
}


.c-checkout {
	thead tr,
	tbody tr,
	tfoot tr {
		display: table-row;
	}

	td,
	tbody tr td:first-child {
		width: auto;
		display: table-cell;
	}

	td,
	th {
		padding: $baseline/1.5;
	}

  thead th {
    padding-top: 0;
    padding-bottom: $baseline/3;
  }

	td:last-child {
		padding-right: 0;
	}

	tbody tr td {
    //padding-top: $baseline/1.5;
    //padding-bottom: $baseline/3;
  	border-top: 2px solid $white;
    vertical-align: top;
	}

	thead th:first-child {
		padding-left: 0;
		text-align: left;
	}

  thead th:last-child {
		padding-right: 0;
  	text-align: right;
	}

	tfoot tr td {
		width: auto;

		&:first-child {
			text-align: left;
		}
		&:last-child .discount {
			text-align: right;
		}
	}
}


/*
  Mobile version
*/

.c-cart-item {
  @include parent-behaviour();
  position: relative;

  border-top: 2px solid $white;

  > div {
      position: relative;
  		margin-top: $baseline/1.5;
      margin-bottom: $baseline/1.5;

      &:first-child {
        padding-left:0;
      }
  }


}

.c-cart-item__price {
  position: absolute;
  right:0;
  top:0;
}

.c-cart-footer {
  @include parent-behaviour();
  position: relative;
  border-top: 2px solid $white;
  border-bottom: 2px solid $white;
}

.c-cart-footer__content {
  position: relative;
  margin-top: $baseline/1.5;
  margin-bottom: $baseline/1.5;

  > li {
      @include parent-behaviour();
      position: relative;
      margin-top: $baseline/1.5;
      margin-bottom: $baseline/1.5;
  }
}

.first--mobile {
  float: left;
}

.last--mobile {
  float: right;
}

#tac {
	p {
		display: inline-block;
		margin-right: 1em;
	}
}

.o-button {
  vertical-align: top;
  width: 100%;
  display: inline-block;
  text-align: left;
  max-width: 15em;
  padding: $baseline/1.5;
  background-color: $textdarkgray;
  color: $white;
  @include transition(background-color, $speed, $ease);
  &:hover {
    background-color: $black;
  }
  @include mq($br-med-large) {
    max-width: 50em;
    padding: $baseline/1.25;
  }
  @include mq($br-med-small) {
    max-width: 50em;
    text-align: center;
    padding: $baseline;
  }
}

.o-button--gateway {
  float:right;
}

.u-inverted .o-button {
  background-color: $textdarkgray;
  color: $black;
  @include transition(background-color, $speed, $ease);
  &:hover {
    background-color: $white;
  }
}

.gateways {
  margin-top: $baseline*1.5;
	button {
    float:right;
		img {
			max-height: 2.5em;
			max-width: 100%;
			display: block;
			margin: 0 auto;
			padding: 0 $baseline/1.5 $baseline/1.5;
		}
		.notification {
			//font-size: $text-s;
			color: black;
			margin: 0 -0.5rem -0.2rem;
		}
	}
}



.o-form {
  display: block;
  position: relative;

  .o-form__group {
    @include transition(opacity, $speed, $ease);
    position: relative;
    padding-top: $baseline*1.5;
    &.has-label-active {
      .o-form__label { opacity: 1; }
    }
  }

  .o-form__group .o-form__label {
    @include transition(opacity, $speed, $ease);
    @include  sans--small();
    text-transform: uppercase;
    position: absolute;
    left:0;
    top: $baseline*1.125;
    font-size: 11px;
    opacity: 0;
  }

  .o-form__group .o-form__label--always-visible {
    opacity: 1;
  }

}

.o-form__select {
  border-bottom: 2px solid #fff;
}

select option{
  font-size: 12px;
}
