.u-inverted {
  background-color: $black !important;
  color: $white !important;
  a, p, ul, li, span, sup, button, strong, em, i {
    color: $white !important;
  }
  button.o-button {
    color: $black !important;
  }
  a {
    p, ul, li, span, sup, button, strong, em, i {
      @include transition(color, $speed, $ease);
    }
  }
  a:hover {
    color: $textdarkgray !important;
    p, ul, li, span, sup, button, strong, em, i {
      color: $textdarkgray !important;
    }
  }
}
