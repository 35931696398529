//Section
//Acts like a wrapper/inner div

.o-section {
	@include section();
}

.o-section--padding {
	@include section--padding();
}

.o-section--padding--less {
	@include section--padding--less();
}

.o-section--padding-top {
	@include section--padding();
	padding-bottom: 0;
}

.o-section--padding--extra {
	@include section--padding--more();
}

.o-section__content {
	@include section--padding--less();
}

.o-section__border {
  border-top: 1px solid $white;
  display:block;
}
