.o-slide {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  height: 100%;
  width: auto;
  overflow: hidden;
  z-index: 0;
}

.o-slide__inner {
  box-sizing: border-box;
  //position: absolute; //This was previously set at relative
  //top: 50%;
  //left: 50%;
  //@include transform(translate(-50%,-50%));
  //width: 100%; //This is overwritten by data attributes
  //height: 100%;   //This is overwritten by data attributes
}

.o-slide__image,
.o-slide__video {
  width: auto;
  height: 100%;
  padding: $padding*3;
}
